/** @format */

import React, { lazy, useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';

const Main = lazy(() => import('./Main'));
const PrivacyPolicy = lazy(() => import('./PrivacyPolicy'));
const TermsAndConditions = lazy(() => import('./TermsAndConditions'));
const CookiePolicy = lazy(() => import('./CookiePolicy'));

const Router = () => {
    const { listen } = useHistory();
    useEffect(() => {
        const unlisten = listen((location) => {
            if (!window.gtag) return;
            window.gtag('config', 'GTM-T8MFKWF', {
                page_path: location.pathname
            });
        });
        return unlisten;
    }, [listen]);

    return (
        <Switch>
            <Route exact path="/privacy-policy">
                <PrivacyPolicy />
            </Route>
            <Route exact path="/terms-and-conditions">
                <TermsAndConditions />
            </Route>
            <Route exact path="/cookie-policy">
                <CookiePolicy />
            </Route>
            <Route exact path="/">
                <Main />
            </Route>
            <Route exact path="/*">
                <Main />
            </Route>
        </Switch>
    );
};

export default Router;
